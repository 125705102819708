import React from 'react'

export default function HomeContent() {
    return (
        <section className="container">
            <div className="columns features">
                <div className="column is-4">
                    <div className="card is-shady">
                        <div className="card-content">
                            <div className="content">
                                <h4>Manage Blackouts</h4>
                                <p>Blackouts created either through this UI or v2 API directly will be displayed in a table. Each row represents
                                a blackout, that can be expanded to show more details. It can also be deleted in its entirety or each individual
                                datadog downtime. All expired blackouts can removed with a single click. The "search" function  will be text
                                searched in all blackout fields.
                                </p>
                                <p><a href="https://ghe.coxautoinc.com/ASI/DDDowntimeUI#manage-blackouts">Learn more from the README in the source code</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card is-shady">
                        <div className="card-content">
                            <div className="content">
                                <h4>Add a Blackout</h4>
                                <p>
                                    A blackout for either production auction servers and lower environment can be added from the UI. Multiple
                                    auctions can be silenced by one blackout. Additional scope keys and/or a different tag name can be
                                    used to silence other datadog monitors. A "system" type blackout will automatically silence monitors with
                                    scope "auction:" and "web service" type blackout will automatically silence monitors with scope "test_name:",
                                    mostly created by IBMi proxy services.
                                </p>
                                <p><a href="https://ghe.coxautoinc.com/ASI/DDDowntimeUI#add-a-blackout">Learn more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="card is-shady">
                        <div className="card-content">
                            <div className="content">
                                <h4>Auditlogs</h4>
                                <p>
                                    All changes to blackout data are recorded by auditlogs. Each auditlog clearly shows which auction was impacted,
                                    when and by whom. It also contains important information that can be used for further more detailed research in
                                    splunk log aggregation.
                                </p>
                                <p><a href="https://ghe.coxautoinc.com/ASI/DDDowntimeUI#auditlogs">Learn more</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
