import React, { Component, Fragment } from 'react';
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import { confirmAlert } from 'react-confirm-alert'
import '../react-confirm-alert.css'

import isBetween from './utility/timefunctions'

import Modal from 'react-modal';

import { V2URL } from './utility/URL'

const { SearchBar } = Search;
const _ = require('lodash');

let nameFilter;

export default class Blackouts extends Component {

  state = {
    isChecked: true,
    loading: true,
    overlay_text: 'Loading...',
    blackouts: [],
    prodAuctions: [],
    error: null,
    modalIsOpen: false,
    modalContent: [],
  }

  options = {
    pageStartIndex: 1,
    sizePerPage: 10,
    // hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: this.state.blackouts.length,
    showTotal: true
  };

  columns = [{
    dataField: 'id',
    text: 'ID',
    headerStyle: () => {
      return { width: "23%" };
    },
  }, {
    dataField: 'env',
    text: 'Env',
    sort: true,
    filter: textFilter(),

    headerStyle: () => {
      return { width: "9%" };
    }
  }, {
    dataField: 'type',
    text: 'Type',
    sort: true,
    filter: textFilter(),

    headerStyle: () => {
      return { width: "9%" };
    }
  }, {
    dataField: 'status',
    text: 'Status',
    sort: true,
    filter: textFilter(),

    headerStyle: () => {
      return { width: "10%" };
    },
    sortValue: (cellContent, row) => {
      return (
        this.status(row.downtimes[0].start_time, row.downtimes[0].end_time)
      );
    },
    filterValue: (cellContent, row) => {
      return (
        this.status(row.downtimes[0].start_time, row.downtimes[0].end_time)
      );
    },
    formatter: (cellContent, row) => {
      return (
        this.status(row.downtimes[0].start_time, row.downtimes[0].end_time)
      );
    }
  }, {
    dataField: 'message',
    text: 'Message',
    sort: true,
    filter: textFilter(),

    headerStyle: () => {
      return { width: "15%" };
    },
  }, {
    dataField: 'auctions',
    text: 'Auctions',
    filter: textFilter(),

    headerStyle: () => {
      return { width: "12%" };
    },
    filterValue: (cellContent, row) => {
      return (
        row.scopes.map(scope => { return scope.auction }).join(', ')
      );
    },
    sort: true,
    sortValue: (cellContent, row) => {
      return (
        row.scopes.map(scope => { return scope.auction }).join(', ')
      );
    },
    formatter: (cellContent, row) => {
      return (
        row.scopes.map(scope => { return scope.auction }).join(', ')
      );
    }
  },
  {
    dataField: 'tag',
    text: 'Tag',
    sort: true,
    headerStyle: () => {
      return { width: "10%" };
    }
  },
  {
    dataField: 'created_at',
    text: 'Created at',
    headerStyle: () => {
      return { width: "12%" };
    },
    formatter: (cellContent, row) => {
      let date_ob = new Date(row.created_at * 1000);

      // year as 4 digits (YYYY)
      let year = date_ob.getFullYear();

      // month as 2 digits (MM)
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

      // date as 2 digits (DD)
      let day = ("0" + date_ob.getDate()).slice(-2);

      // hours as 2 digits (hh)
      let hours = ("0" + date_ob.getHours()).slice(-2);

      // minutes as 2 digits (mm)
      let minutes = ("0" + date_ob.getMinutes()).slice(-2);

      // seconds as 2 digits (ss)
      let seconds = ("0" + date_ob.getSeconds()).slice(-2);

      return (
        year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds
      );
    },
  },
  {
    dataField: 'creator',
    text: 'Creator',
    sort: true,
    headerStyle: () => {
      return { width: "10%" };
    },
    filter: textFilter({
      defaultValue: this.props.auth.user.username.split('@')[0],
      getFilter: (filter) => {
        // nameFilter was assigned once the component has been mounted.
        nameFilter = filter;
      }
    })
  },
  {
    dataField: 'action',
    text: 'Action',
    headerStyle: () => {
      return { width: "7%" };
    },
    formatter: (cellContent, row) => {
      return (
        <button
          className="btn btn-danger btn-xs"
          onClick={() => this.confirm(this.deleteBlackout, row, row.id)}
        >
          Remove
        </button >
      );
    },
  }];

  defaultSorted = [{
    dataField: 'created_at',
    order: 'desc'
  }];

  expandRow = {
    onlyOneExpanding: true,
    showExpandColumn: true,
    renderer: row => (
      <div className="container bg-1">
        <p>created_at: {(new Date(Math.ceil(row.created_at * 1000))).toString()}  ({(Math.ceil(row.created_at))} sec)</p>
        <p>updated_at: {(new Date(Math.ceil(row.updated_at * 1000))).toString()}  ({(Math.ceil(row.updated_at))} sec)</p>
        <p>duration: {row.duration / 60} mins</p>
        <p>originating server: {row.server}</p>
        <table className="table table-striped table-bordered bg-2 table-responsive">
          <thead>
            <tr>
              <th className="col-sm-2">datadog id</th>
              <th className="col-sm-2">scope</th>
              <th className="col-sm-1">status</th>
              <th className="col-sm-2">start</th>
              <th className="col-sm-2">end</th>
              <th className="col-sm-4">message</th>
              <th className="col-sm-1">affected monitors</th>
              <th className="col-sm-1">action</th>
            </tr>
          </thead>
          <tbody>
            {row.downtimes.map((downtime, index) => (
              <tr key={index} id={index}>
                <td>{downtime.id}</td>
                <td>{downtime.scope}</td>
                <td>{this.status(downtime.start_time, downtime.end_time)}</td>
                <td>{(new Date(downtime.start_time * 1000)).toLocaleString()}</td>
                <td>{(new Date(downtime.end_time * 1000)).toLocaleString()}</td>
                <td>{downtime.message}</td>
                <td className="text-center"><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} onClick={() => this.preview(row, downtime)} /></td>
                <td className="text-center"><FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} onClick={() => this.confirm(this.deleteDowntime, row, row.id, downtime.id)} /></td>
              </tr>
            ))}
          </tbody>
        </table>

        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          style={{
            overlay: {
              position: 'absolute',
              top: '20%',
              left: '20%',
              right: '20%',
              bottom: '20%',
              backgroundColor: 'rgba(255, 255, 255, 1)'
            },
            content: {
              position: 'absolute',
              top: '10px',
              left: '10px',
              right: '10px',
              bottom: '10px',
              border: '0px solid #ccc',
              background: '#fff',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
          }}
        >

          <div>{this.renderModal()}</div>
        </Modal>
      </div>
    )
  };

  renderModal = () => {
    if (this.state.modalContent !== null) {
      return (
        <div
        // style={{ width: 400, height: 800, backgroundColor: "white" }}
        >
          <h2>Affected Datadog Monitors</h2>
          <p>&nbsp;</p>
          <ul>
            {this.state.modalContent.map((monitor) => (
              <li key={monitor}>{monitor}</li>
            ))}
          </ul>
          <p>&nbsp;</p>
          <button onClick={this.closeModal}>Close</button>
        </div>
      );
    }
  }

  status = (start, end) => {
    return isBetween(start * 1000, end * 1000) === 1 ? "active"
      : isBetween(start * 1000, end * 1000) === 0 ? "scheduled" : "expired"
  }

  preview = async (row, downtime) => {
    try {
      console.log('previewing affected monitors ....');

      this.setState({ loading: true, overlay_text: 'Loading...' });

      if (isBetween(downtime.start_time * 1000, downtime.end_time * 1000) < 0) {
        console.log('already expired. no affected monitors');
        this.setState({ modalContent: ['already expired. no affected monitors'] });

        this.setState({ loading: false });
        this.setState({ modalIsOpen: true });

        return;
      }

      const res = await axios.get(`${V2URL}/monitors_search?tag=${row.tag}&scope=${downtime.scope}`);

      // transform data to json object
      let monitors = res.data.map((data) => {
        let monitor = data;
        // console.log('monitor:', monitor);
        return monitor;
      });

      console.log("monitors", monitors);

      this.setState({ loading: false });
      this.setState({ modalIsOpen: true });
      this.setState({ modalContent: monitors });

    } catch (error) {
      console.log(`An error has occurred: ${error.message}`);
      this.setState({ error: error.message });
      this.setState({ loading: false });
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  confirm = (func, row, id1 = 0, id2 = 0) => {
    console.log('row', row);
    if (row.creator !== this.props.auth.user.username.split('@')[0] &&
      (this.status(row.downtimes[0].start_time, row.downtimes[0].end_time) === "active" ||
        this.status(row.downtimes[0].start_time, row.downtimes[0].end_time) === "scheduled")) {
      confirmAlert({
        title: 'Confirm to delete',
        message: 'Are you sure to delete blackout/downtime created by ' + row.creator + '?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => func(id1, id2)
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      })
    } else {
      func(id1, id2);
    }
  }

  fetchBlackouts = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      console.log('fetching blackouts list ....')

      let res = await axios.get(`${V2URL}/list`);

      // transform data to json object
      let newdata = res.data.map((data) => {
        let blackout = JSON.parse(data);
        return blackout;
      });

      // console.log('newdata', newdata);
      this.setState({ blackouts: newdata });
      console.log('found ' + newdata.length + ' blackout');
    } catch (error) {
      console.log(`An error has occurred: ${error.message}`);
      this.setState({ error: error.message });
      this.setState({ loading: false });
    }
  }

  deleteBlackout = async (id) => {
    try {
      console.log(`deleting blackout (${id}) ....`);

      this.setState({ loading: true, overlay_text: 'Deleting...' });

      const res = await axios.delete(`${V2URL}/${id}?actor=${this.props.auth.user.username.split('@')[0]}`);

      console.log('delete resp:', res);

      // remove it from state
      let newBlackouts = _.filter(this.state.blackouts, (row) => { return row.id !== id });

      this.setState({ blackouts: newBlackouts });
      this.setState({ loading: false });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ error: err.message });
      this.setState({ loading: false });
    }
  }

  deleteDowntime = async (blackout_id, downtime_id) => {
    try {
      console.log(`deleting downtime (${blackout_id}, ${downtime_id}) ....`);

      this.setState({ loading: true, overlay_text: 'Deleting...' });

      const res = await axios.delete(`${V2URL}/${blackout_id}?downtime_id=${downtime_id}&actor=${this.props.auth.user.username.split('@')[0]}`);

      console.log('delete downtime resp:', res);

      // get the new list from api
      this.fetchBlackouts();

      this.setState({ loading: false });

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ error: err.message });
      this.setState({ loading: false });
    }
  }

  deleteExpired = async () => {
    try {

      // let sleep = (milliseconds) => {
      //   return new Promise(resolve => setTimeout(resolve, milliseconds))
      // }

      console.log(`deleting expired blackouts ....`);

      this.setState({ loading: true, overlay_text: 'Deleting...' });

      let count = 0;
      await Promise.all(this.state.blackouts.map(async (blackout, index) => {
        let downtime = blackout.downtimes[0];
        if (this.state.isChecked) {
          // only delete the self owned expired
          if (blackout.creator === this.props.auth.user.username.split('@')[0] &&
            isBetween(downtime.start_time * 1000, downtime.end_time * 1000) < 0) {
            // expired. delete the blackout
            console.log("deleting self created blackout id=" + blackout.id);
            await this.deleteBlackout(blackout.id);
            count++;
          }
        }
        else {
          if (isBetween(downtime.start_time * 1000, downtime.end_time * 1000) < 0) {
            // expired. delete the blackout regardless of creator
            console.log("deleeting blackout id=" + blackout.id);
            await this.deleteBlackout(blackout.id);
            count++;
          }
        }
      }));

      this.setState({ loading: false });

      if (count === 0) {
        console.log("nothing deleted");
      } else {
        console.log(count + " blackout deleted");
      }
      return count;

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ error: err.message });
      this.setState({ loading: false });
    }
  }

  componentDidMount = () => {
    this.fetchBlackouts().then((l) => {
      if (this.state.blackouts.length === 0) {
        console.log('no blackouts found. setting loading to false');
        this.setState({ loading: false });
      }

      if (this.props.prodAuctions !== undefined && this.props.prodAuctions.length !== 0) {
        this.setState({ prodAuctions: this.props.prodAuctions });

        console.log('setting loading to false');
        this.setState({ loading: false });
      }
      console.log('this', this);
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log('in componentDidUpdate');
    console.log('prevProps=', prevProps);
    console.log('prevState=', prevState);
    console.log('this.props=', this.props);
    console.log('this.state=', this.state);

    if (this.props.prodAuctions === undefined || this.props.prodAuctions.length === 0) {
      console.log('data not ready yet');
      return;
    }

    // only if both blackouts[] avail and env hasn't been updated
    if (this.props.prodAuctions.length !== 0 && this.state.blackouts.length !== 0 &&
      (this.state.blackouts[0].env === undefined || this.state.blackouts[0].env === '')) {
      let copyOfBlackouts = JSON.parse(JSON.stringify(this.state.blackouts));
      let newBlackouts = copyOfBlackouts.map(blackout => {
        console.log('orig blackout=', blackout);
        let auctionLabel = blackout.scopes[0].auction.toUpperCase();
        console.log('auction=', auctionLabel);
        let auctionObj = this.props.prodAuctions.find(auction => auction.label === auctionLabel);
        blackout.env = auctionObj ? "PROD" : "NP";
        // if *, level it blank
        if (auctionLabel === "*") {
          blackout.env = " ";
        }
        console.log('new blackout=', blackout);
        return blackout;
      });

      this.setState(state => ({ blackouts: newBlackouts }));
      this.setState({ loading: false });

      console.log('this', this);
    }
  }

  toggleCheckboxChange = (event) => {

    console.log('event', event);
    this.setState({ isChecked: !this.state.isChecked });

    if (event.target.checked) {
      nameFilter(this.props.auth.user.username.split('@')[0]);
    } else {
      nameFilter('');
    }
  };

  render() {
    return (
      <Fragment>
        <h1>Manage Blackouts</h1>
        <br></br>
        <LoadingOverlay
          active={this.state.loading}
          spinner={<BounceLoader />}
          text={this.state.overlay_text}
        >
          {this.state.error && (
            <div className="alert alert-danger">Error received from API : <strong>{this.state.error}</strong> </div>
          )}
          {!this.state.error && (
            <ToolkitProvider
              keyField="id"
              data={this.state.blackouts}
              columns={this.columns}
              search
            >
              {
                props => (
                  <div>
                    <div className="input-group row justify-content-between align-items-center">
                      <div className="input-group-prepend col-sm-3">
                        <span className="input-group-text" id="label">Input something to search:</span>
                      </div>
                      <div className="col-sm-4" >
                        <SearchBar {...props.searchProps} />
                      </div>
                      <div className="col-sm-2"></div>
                      <div className="col-sm-3" >
                        <input type="checkbox" checked={this.state.isChecked} onChange={(event) => this.toggleCheckboxChange(event)} /> Only Display Blackouts Created by Loggedin User
                      </div>
                    </div>

                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={this.expandRow} defaultSorted={this.defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory(this.options)}
                    />
                    <div>
                      <button className="btn btn-danger btn-xs"
                        onClick={() => this.deleteExpired()}> Remove All Expired</button>
                    </div>
                  </div>
                )
              }
            </ToolkitProvider>
          )}
        </LoadingOverlay>
      </Fragment>
    )
  }
}