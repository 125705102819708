

function isBetween(start_time, end_time, current_time = new Date().getTime()) {
  let startTime = new Date(start_time);
  let endTime = new Date(end_time);
  let currentTime = new Date(current_time);

  // -1 : expired
  // 1 : active
  // 0 : scheduled
  return currentTime.getTime() > endTime.getTime() ? -1 : currentTime.getTime() > startTime.getTime() ? 1 : 0
}


export default isBetween