import React, { Component, Fragment } from 'react';
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { textFilter } from 'react-bootstrap-table2-filter';


import isBetween from './utility/timefunctions'

import { V2URL } from './utility/URL'


const { SearchBar } = Search;


export default class Auditlogs extends Component {

  state = {
    loading: true,
    overlay_text: 'Loading...',
    auditlogs: [],
    prodAuctions: [],
    error: null
  }

  options = {
    pageStartIndex: 1,
    paginationSize: 10,
    sizePerPage: 10,
    // hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
    totalSize: this.state.auditlogs.length,
    showTotal: true
  };

  columns = [{
    dataField: 'blackout_id',
    text: 'Blackout ID',
    headerStyle: () => {
      return { width: "25%" };
    },
  }, {
    dataField: 'action',
    text: 'Action',
    filter: textFilter(),
    sort: true,
    headerStyle: () => {
      return { width: "7%" };
    },
  }, {
    dataField: 'env',
    text: 'Env',
    filter: textFilter(),
    sort: true,
    headerStyle: () => {
      return { width: "7%" };
    },

  }, {
    dataField: 'duration',
    text: 'Duration (secs)',
    sort: true,
    headerStyle: () => {
      return { width: "7%" };
    },
  }, {
    dataField: 'status',
    text: 'Blackout Status',
    filter: textFilter(),
    formatter: (cellContent, row) => {
      return (
        row.blackoutStatus
      );
    },
    filterValue: (cellContent, row) => {
      return (
        row.blackoutStatus
      );
    },
    headerStyle: () => {
      return { width: "10%" };
    },
  }, {
    dataField: 'type',
    text: 'Type',
    filter: textFilter(),
    formatter: (cellContent, row) => {
      let resp = null;
      if (row.origBlackout != null) {
        resp = row.origBlackout.type;
      } else {
        resp = "(failed)"
      }
      return (
        resp
      );
    },
    filterValue: (cellContent, row) => {
      let resp = null;
      if (row.origBlackout != null) {
        resp = row.origBlackout.type;
      } else {
        resp = "(failed)"
      }
      return (
        resp
      );
    },
    headerStyle: () => {
      return { width: "7%" };
    },
  },
  {
    dataField: 'auctions',
    filter: textFilter(),
    text: 'Auctions',
    headerStyle: () => {
      return { width: "10%" };
    }
  },
  {
    dataField: 'creator',
    text: 'Actor|Creator',
    sort: true,
    formatter: (cellContent, row) => {
      return (
        row.actor + " | " + row.creator
      );
    },
    filterValue: (cellContent, row) => {
      return (
        row.actor + " | " + row.creator
      );
    },
    headerStyle: () => {
      return { width: "15%" };
    },
  },
  {
    dataField: 'created_at',
    text: 'Created At',
    sort: true,
    headerStyle: () => {
      return { width: "13%" };
    },
    sortValue: (cellContent, row) => {
      return (
        row.expiration
      );
    },
    formatter: (cellContent, row) => {
      return (
        (new Date(Math.ceil((row.expiration - 1209600) * 1000))).toLocaleString()
      );
    },
    filterValue: (cellContent, row) => {
      return (
        (new Date(Math.ceil((row.expiration - 1209600) * 1000))).toLocaleString()
      );
    },
  },
  {
    dataField: 'bogus',
    text: 'hidden columns',
    headerStyle: () => {
      return { width: "0%" };
    },
    show: false,
    filterValue: (cellContent, row) => {
      let readableExpiration = (new Date(Math.ceil((row.expiration) * 1000))).toString();
      return (
        row.expiration.toString().concat(row.aws_trace_id, row.blackout_id, row.path, row.response, row.orig_blackout, readableExpiration)
      );
    },
  }];

  defaultSorted = [{
    dataField: 'created_at',
    order: 'desc'
  }];

  expandRow = {
    onlyOneExpanding: true,
    showExpandColumn: true,
    renderer: row => (
      <div className="container bg-1">
        <p><b>auditlog id:</b> {row.id}</p>
        <p><b>originating server:</b> {row.server}</p>
        <p><b>audit record expiration:</b> {(new Date(Math.ceil((row.expiration) * 1000))).toString()}  ({(Math.ceil(row.expiration))} sec)</p>
        <p><b>aws_trace_id:</b> {row.aws_trace_id}</p>
        <p><b>path:</b> {row.path}</p>
        <p><b>body:</b> {row.body}</p>
        <p><b>orig_blackout:</b>{row.orig_blackout}</p>
        <p><b>response:</b> {row.response}</p>
      </div>
    )
  };

  fetchAuditlogs = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      console.log('fetching auditlog list ....');

      let data = null;

      let response = await axios.get(`${V2URL}/auditlogs`);

      console.log('response', response);
      data = [...response.data];

      let last_evaluated_key = response.headers['lastevaluatedkey'];
      while (last_evaluated_key !== undefined) {
        console.log('LastEvaluatedKey=', last_evaluated_key);
        const response2 = await axios.get(`${V2URL}/auditlogs`, { params: { LastEvaluatedKey: last_evaluated_key } });
        console.log('response2', response2);
        data.push.apply(data, [...response2.data])
        last_evaluated_key = response2.headers['lastevaluatedkey'];
      }

      // transform data to json object
      let newdata = data.map((item) => {
        // console.log('item', item);

        item.origBlackout = this.origBlackout(item);
        item.blackoutStatus = this.blackoutStatus(item);

        if (item.action === "DELETE") {
          let endTime = item.expiration - 1209600;
          let startTime = item.origBlackout.created_at;
          console.log("duration=", Math.ceil((endTime - startTime)));

          item.duration = Math.ceil((endTime - startTime));
        }

        return item;
      });

      // console.log('newdata', newdata);
      this.setState({ auditlogs: newdata });
      console.log('found ' + this.state.auditlogs.length + ' auditlogs');

    } catch (error) {
      console.log(`An error has occurred: ${error.message}`);
      this.setState({ error: error.message });
      this.setState({ loading: false });
    }
  }

  origBlackout = (data) => {
    let orig_blackout_str = data.orig_blackout.replace(/\\"/g, '"');
    // console.log('orig_blackout_text=', orig_blackout_str);
    let orig_blackout = null;
    if (orig_blackout_str && orig_blackout_str !== "") {
      orig_blackout = JSON.parse(orig_blackout_str);
      // console.log("orig_blackout_obj=", orig_blackout);
    }
    return orig_blackout;
  }

  blackoutStatus = (data) => {
    let orig_blackout = data.origBlackout;

    if (orig_blackout == null || orig_blackout.downtimes == null) return "(failed)";

    let start_time = orig_blackout.downtimes[0].start_time
    let end_time = orig_blackout.downtimes[0].end_time
    return (
      isBetween(start_time * 1000, end_time * 1000, (data.expiration - 1209600) * 1000) === 1 ? "active"
        : isBetween(start_time * 1000, end_time * 1000, (data.expiration - 1209600) * 1000) === 0 ? "scheduled" : "expired"
    );
  }

  componentDidMount = () => {
    this.fetchAuditlogs().then((l) => {
      if (this.props.prodAuctions !== undefined && this.props.prodAuctions.length !== 0) {
        this.setState({ prodAuctions: this.props.prodAuctions });

        console.log('setting loading to false');
        this.setState({ loading: false });
      }
      console.log('this', this);
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log('in componentDidUpdate');
    console.log('prevProps=', prevProps);
    console.log('prevState=', prevState);
    console.log('this.props=', this.props);
    console.log('this.state=', this.state);

    if (this.props.prodAuctions === undefined || this.props.prodAuctions.length === 0) {
      console.log('data not ready yet');
      return;
    }

    // only if both blackouts[] avail and env hasn't been updated
    if (this.props.prodAuctions.length !== 0 && this.state.auditlogs.length !== 0 &&
      (this.state.auditlogs[0].env === undefined || this.state.auditlogs[0].env === '')) {
      let copyOfAuditlogs = JSON.parse(JSON.stringify(this.state.auditlogs));
      let newAuditlogs = copyOfAuditlogs.map(auditlog => {
        console.log('orig auditlog=', auditlog);
        let auctionLabel = auditlog.auctions.split(',')[0].toUpperCase();
        console.log('auction=', auctionLabel);
        let auctionObj = this.props.prodAuctions.find(auction => auction.label === auctionLabel);
        auditlog.env = auctionObj ? "PROD" : "NP";
        // if *, level it blank
        if (auctionLabel === "*") {
          auditlog.env = " ";
        }
        console.log('new blackout=', auditlog);
        return auditlog;
      });

      this.setState(state => ({ auditlogs: newAuditlogs }));
      this.setState({ loading: false });

      console.log('this', this);
    }
  }

  render() {
    return (
      <Fragment>
        <h1>Audit Logs</h1>
        <br></br>
        <LoadingOverlay
          active={this.state.loading}
          spinner={<BounceLoader />}
          text={this.state.overlay_text}
        >
          {this.state.error && (
            <div className="alert alert-danger">Error received from API : <strong>{this.state.error}</strong> </div>
          )}
          {!this.state.error && (
            <ToolkitProvider
              keyField="id"
              data={this.state.auditlogs}
              columns={this.columns}
              search
            >
              {
                props => (
                  <div>
                    <div className="input-group row justify-content-start align-items-center">
                      <div className="input-group-prepend col-sm-3">
                        <span className="input-group-text" id="label">Input something to search:</span>
                      </div>
                      <div className="col-sm-4" >
                        <SearchBar {...props.searchProps} />
                      </div>
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      expandRow={this.expandRow} defaultSorted={this.defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory(this.options)
                      }
                    />
                    <hr />
                  </div>
                )
              }
            </ToolkitProvider>
          )}
        </LoadingOverlay>
      </Fragment>
    )
  }
}