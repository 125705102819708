import React, { Fragment } from 'react';
//import Hero from './Hero';
import HomeContent from './HomeContent';

export default function Home() {
  return (
    <Fragment>
      {/* <Hero /> */}
      <div className="box cta">

        <p className="has-text-centered">
          <span className="tag is-primary">New</span> audit logs has been added for researching and troubleshooting the blackout creation/deletion.
          <p>
            (logo designed using https://logomakr.com/8iiNqD#)
          </p>
          <span className="tag is-danger">Notice</span> Cox VPN must be connected in order to access the downtime api.
        </p>
      </div>
      <HomeContent />
    </Fragment>
  )
}
