import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import { Auth } from "aws-amplify";
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";

class Register extends Component {
  state = {
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    errors: {
      cognito: null,
      blankfield: false,
      passwordbad: false,
      passwordmatch: false,
      emailbad: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordbad: false,
        passwordmatch: false,
        emailbad: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
      console.log(error)
      return;
    }

    // console.log('in handleSubmit');
    // AWS Cognito integration here
    const { username, firstname, lastname, email, password } = this.state;
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          email: email,
          family_name: lastname,
          given_name: firstname
        }
      });
      this.props.history.push("/registrationwelcome");
      console.log(signUpResponse);
    } catch (error) {
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      });
    }
  }

  onInputChange = event => {
    // console.log('onInputChange. event=', event);
    this.setState({
      [event.target.id]: event.target.value
    });
    switch (event.target.id) {
      case "email":
        if (!event.target.value.endsWith("@coxautoinc.com")) {
          document.getElementById(event.target.id).classList.add("is-danger");
          this.setState({
            errors: {
              ...this.state.errors,
              emailbad: true
            }
          });
        } else {
          document.getElementById(event.target.id).classList.remove("is-danger");
          this.setState({
            errors: {
              ...this.state.errors,
              emailbad: false
            }
          });
        }
        break;

      default:
        document.getElementById(event.target.id).classList.remove("is-danger");
    }
  }

  render() {
    return (

      <section className="section auth">
        <div className="container">
          <h1>Register</h1>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  id="username"
                  aria-describedby="userNameHelp"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={this.onInputChange}
                  data-tip
                  data-for="username-requirement"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
              <ReactTooltip id="username-requirement" place="right" effect="solid">
                username is recommended to be the same as email address
              </ReactTooltip>
            </div>
            <div className="field">
              <p className="control">
                <input
                  className="input"
                  type="text"
                  id="firstname"
                  aria-describedby="userNameHelp"
                  placeholder="Enter first name"
                  value={this.state.firstname}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control">
                <input
                  className="input"
                  type="text"
                  id="lastname"
                  aria-describedby="userNameHelp"
                  placeholder="Enter last name"
                  value={this.state.lastname}
                  onChange={this.onInputChange}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  className="input"
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                  data-tip
                  data-for="email-requirement"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
              <ReactTooltip id="email-requirement" place="right" effect="solid">
                requirement: email address must be from @coxautoinc.com
              </ReactTooltip>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                  data-tip
                  data-for="password-requirement"
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
              <ReactTooltip id="password-requirement" place="right" effect="solid">
                requirement: password must contain at least 1 number, 1 special char,
                1 upper and 1 lower and more than 8 chars long
              </ReactTooltip>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  className="input"
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Register
                </button>
              </p>
            </div>
          </form>
        </div>
      </section >
    );
  }
}

export default Register;