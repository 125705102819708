import React, { Component, Fragment } from 'react';
import axios from "axios";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

import { V2URL } from './utility/URL'

export default class About extends Component {

  state = {
    loading: true,
    error: null,
    version: {
      DEPLOY_TIME: null,
      GIT_COMMIT_SHORT: null,
      GIT_BRANCH_NAME: null
    }
  }

  fetchVersion = async () => {
    // add call to AWS API Gateway to fetch products here
    // then set them in state
    try {
      console.log('fetching version info ....');

      this.setState({ loading: true, overlay_text: 'Loading...' });

      const res = await axios.get(`${V2URL}/version`);

      console.log(res.data);
      // // transform data to json object
      // let newdata = res.data.map((data) => {
      //   let blackout = JSON.parse(data);
      //   console.log('version', blackout);
      //   return blackout;
      // });

      // console.log('newdata', newdata);
      this.setState({ version: res.data });
      // console.log(this.state.version.DEPLOY_TIME)
      this.setState({ loading: false });

    } catch (error) {
      console.log(`An error has occurred: ${error.message}`);
      this.setState({ error: error.message });
      this.setState({ loading: false });
    }
  }

  componentDidMount = () => {
    this.fetchVersion().then((l) => {
      console.log('setting loading to false');

      this.setState({ loading: false })
    });
  }

  componentDidUpdate = () => {
    console.log('in componentDidUpdate');
  }

  render() {
    return (
      <Fragment>
        <h1>About</h1>
        <br></br>
        <LoadingOverlay
          active={this.state.loading}
          spinner={<BounceLoader />}
          text={this.state.overlay_text}
        >
          {this.state.error && (
            <div className="alert alert-danger">Error received from API : <strong>{this.state.error}</strong> </div>
          )}
          {!this.state.error && (
            <div className="columns features">
              <div className="card col-5">
                <div className="card-body">
                  <h2 className="card-title">Downtime UI</h2>
                  <br></br>
                  <p className="card-text">
                    <strong>Git Branch Name:</strong> {process.env.REACT_APP_GIT_BRANCH_NAME}
                  </p>
                  <p className="card-text">
                    <strong>GIT Commit Hash:</strong> {process.env.REACT_APP_GIT_COMMIT_ID}
                  </p>
                  <p className="card-text">
                    <strong>React Version:</strong> {React.version}
                  </p>
                  <p className="card-text">
                    <strong>NodeJS:</strong> {process.env.NODE_ENV}
                  </p>
                  <p className="card-text">
                    <strong>Build Time:</strong> {process.env.REACT_APP_BUILD_TIME}
                  </p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
              <div className="card col-5">
                <div className="card-body">
                  <h2 className="card-title">Downtime API</h2>
                  <br></br>
                  <p className="card-text">
                    <strong>Git Branch Name:</strong> {this.state.version.GIT_BRANCH_NAME}
                  </p>
                  <p className="card-text">
                    <strong>GIT Short Hash:</strong> {this.state.version.GIT_COMMIT_SHORT}
                  </p>
                  <p className="card-text">
                    <strong>Deploy Time:</strong> {this.state.version.DEPLOY_TIME}
                  </p>
                  <p className="card-text">
                    <strong>Endpoint:</strong> {V2URL}
                  </p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            </div>
          )}
        </LoadingOverlay>

      </Fragment>
    )
  }
}
