import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class Navbar extends Component {

  state = {
    admin: false
  }

  handleLogOut = async event => {
    event.preventDefault();
    try {
      Auth.signOut();
      this.props.auth.setAuthStatus(false);
      this.props.auth.setUser(null);
      console.log("signing out...");
    } catch (error) {
      console.log(error.message);
    }
  }
  componentDidMount = () => {
    if (this.props.auth.user) {
      // console.log("user=", this.props.auth.user['signInUserSession']['idToken']['payload']['cognito:groups']);
      let groups = this.props.auth.user['signInUserSession']['idToken']['payload']['cognito:groups'];
      if (groups !== undefined) {
        this.setState({ admin: groups.includes('admin') });
      } else {
        this.setState({ admin: false });
      }
    }
  }
  render() {
    return (
      <nav className="navbar navbar-default" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/home">
            <img src="downtimeui.png" width="100" height="100" alt="downtime ui logo" />
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <a href="/home" className="navbar-item active">
              Home
            </a>
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <a href="/blackouts" className="navbar-item menu-border">
                Manage Blackouts
              </a>
            )}
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <a href="/add" className="navbar-item menu-border">
                Add Blackout
              </a>
            )}
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <a href="/auditlogs" className="navbar-item menu-border">
                Auditlogs
              </a>
            )}
            {this.props.auth.isAuthenticated && this.props.auth.user && (
              <a href="/about" className="navbar-item menu-border">
                About
              </a>
            )}
            {this.props.auth.isAuthenticated && this.props.auth.user && this.state.admin && (
              <a href="#" className="navbar-item menu-border">
                Admin
              </a>
            )}
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              {this.props.auth.isAuthenticated && this.props.auth.user && (
                <p>
                  Hello {this.props.auth.user.username}
                </p>
              )}
              <div className="buttons">
                {!this.props.auth.isAuthenticated && (
                  <div>
                    <a href="/register" className="button is-primary">
                      <strong>Register</strong>
                    </a>
                    <a href="/login" className="button is-light">
                      Log in
                    </a>
                  </div>
                )}
                {this.props.auth.isAuthenticated && (
                  <a href="/home" onClick={this.handleLogOut} className="button is-light">
                    Log out
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
