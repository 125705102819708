import React from 'react'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          {/* Hexal 2019. The source code is licensed MIT. The website content is licensed CC BY NC SA 4.0. */}
          {/* {window.REACT_APP_GIT_HASH} */}
          BRANCH:{process.env.REACT_APP_GIT_BRANCH_NAME}
          HASH:{process.env.REACT_APP_GIT_COMMIT_ID}
          {/* %REACT_APP_RESOLVED_SOURCE_VERSION% */}
        </p>
      </div>
    </footer>
  )
}
