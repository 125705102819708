import React, { Component, Fragment } from 'react';
import axios from "axios";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DateTimePicker from 'react-datetime-picker';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
import ReactTooltip from "react-tooltip";

import { V2URL } from './utility/URL'

const Validator = require('jsonschema').Validator;

const animatedComponents = makeAnimated()

export default class Blackout extends Component {

  state = {
    loading: true,
    overlay_text: 'Loading...',
    environment: "prod",
    type: 'ws',
    scopes: [],
    start: new Date(),
    duration: 60,
    message: 'calling api from UI',
    tag: 'Application:IBMi',
    scopekeys: 'server',
    selectedAuctions: [],
    auctions: [],
    error: ""
  }

  schema = {
    "type": "object",
    "properties": {
      "type": {
        "type": "string",
      },
      "duration": {
        "type": "integer",
        "minimum": 1
      },
      "message": {
        "type": "string"
      },
      "tag": {
        "type": "string"
      },
    },
    "required": ["type", "duration", "tag"]
  };

  onScopeKeysChange = event => {
    console.log('in onScopeKeysChange', event.target.value);

    this.setState({
      scopekeys: event.target.value
    })
  }
  onTagChange = event => {
    console.log('in onTagChange', event.target.value);

    this.setState({
      tag: event.target.value
    })
  }
  onMessageChange = event => {
    console.log('in onTagChange', event.target.value);

    this.setState({
      message: event.target.value
    })
  }

  onDurationChange = event => {
    console.log('in onDurationChange', event.target.value);

    this.setState({
      duration: parseInt(event.target.value)
    })
  }

  onStartChange = newtime => {
    console.log('in onStartChange', newtime);

    this.setState({
      start: newtime
    })
  }

  onTypeChange = event => {
    console.log('in onTypeChange', event.target.value);

    this.setState({
      type: event.target.value
    })
  }

  onEnvChange = event => {
    console.log('in onEnvChange', event.target.value);

    let auctions = event.target.value === "prod" ? this.props.prodAuctions : this.props.npAuctions;

    console.log('in onEnvChange', auctions);

    this.setState({ auctions: auctions })
    this.setState({ environment: event.target.value })

    // clear already selected value
    this.setState({ selectedAuctions: [] });
  }

  handleAuctionSelectionChange = selectedOption => {
    this.setState({ selectedAuctions: selectedOption }, () => console.log(`Option selected:`, selectedOption));
  }

  isStartInFutureDate = (blackout) => {
    console.log('in isStartInFutureDate');
    // start date must be in the future: start date (in eastern time) - eastern offset > current epoch time
    //
    // offset is positive if behind UTC, ie. UTC-5 will yield positive 5 hours

    console.log('local offset = ' + (new Date()).getTimezoneOffset());
    console.log('(new Date()).valueOf() = ' + (new Date()).valueOf());
    console.log('eastern offse = ' + this.getEstOffset());
    return ((new Date()).valueOf() - blackout.start * 1000 < 0);
  }

  // Get time zone offset for NY, USA
  getEstOffset = () => {
    console.log('in getEstOffset');

    const stdTimezoneOffset = () => {
      var jan = new Date(0, 1)
      var jul = new Date(6, 1)
      return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    }

    var today = new Date()

    const isDstObserved = (today) => {
      return today.getTimezoneOffset() < stdTimezoneOffset()
    }

    if (isDstObserved(today)) {
      console.log("in DST");
      return 4 * 60
    } else {
      console.log("not in DST");
      return 5 * 60
    }
  }

  validateInput = (blackout) => {
    console.log("blackout=", blackout);

    let v = new Validator();

    let result = v.validate(blackout, this.schema);

    console.log("result=", result);

    if (result.errors.length > 0) {
      return result.errors[0].stack
    }

    if (blackout.scopes.length < 1) {
      let error = "no auction selected";

      return error;
    }

    if (!this.isStartInFutureDate(blackout)) {
      let error = "start date is required to be in the future";

      return error;
    }

    return
  }

  handleAddProduct = async (newblackout, event) => {
    event.preventDefault();
    // add call to AWS API Gateway add product endpoint here
    try {
      this.setState({ loading: true, overlay_text: 'Adding...' });

      console.log('state=', this.state);

      const params = this.state.environment === "np" ? { env: 'lower' } : {};

      console.log('params=', params);

      // clear out error
      this.setState({
        error: null
      });

      const newBlackout = {
        type: this.state.type,
        duration: this.state.duration * 60,
        message: this.state.message,
        start: Math.round(new Date(this.state.start).valueOf() / 1000),  // in local seconds
        creator: this.props.auth.user.username.split('@')[0],
        scopes: this.state.selectedAuctions.map((auction) => {
          return { auction: auction.value };
        }),
        tag: this.state.tag,
        scope_keys: this.state.scopekeys
      }

      // this.state.start contains local time. set it to eastern time zone
      newBlackout.start = newBlackout.start - (new Date()).getTimezoneOffset() * 60 + this.getEstOffset() * 60;

      const error = this.validateInput(newBlackout);
      if (error) {
        this.setState({
          error: error
        });
        console.log(error)

        this.setState({ loading: false });

        return;
      }

      await axios.post(`${V2URL}`, newBlackout, { params });

      this.setState({ loading: false });

      this.props.history.push("/blackouts");

    } catch (err) {
      console.log(`An error has occurred: ${err}`);
      this.setState({ error: err.message });
      this.setState({ loading: false });

    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log('in componentDidUpdate, this.props=', this.props);

    if (this.props.parentError !== '' && this.state.error === '') {
      console.log('failed to get auctions');
      this.setState({ loading: false })
      this.setState({ error: this.props.parentError + ' - failed to get auctions' });

      return;
    }

    if (this.props !== prevProps && this.props.prodAuctions.length !== 0 && this.props.npAuctions.length !== 0) {
      this.setState({ prodAuctions: this.props.prodAuctions });
      this.setState({ npAuctions: this.props.npAuctions });
      this.setState({ auctions: this.props.prodAuctions });

      this.setState({ loading: false })
    }
    // console.log('prevProps=', prevProps);
    // console.log('prevState=', prevState);
  }

  render() {

    return (
      <Fragment>
        <LoadingOverlay
          active={this.state.loading}
          spinner={<BounceLoader />}
          text={this.state.overlay_text}
        >
          <div className="container-fluid">
            <h1>Add a Blackout</h1>
            <br />
            <form className="form-horizontal" onSubmit={event => this.handleAddProduct(this.state.newblackout, event)}>
              {this.state.error &&
                <div className="form-group row mb-3">
                  <div className="alert alert-danger col-sm-7">
                    <div className="justify-content-left">
                      Error: {this.state.error}
                    </div>
                  </div>
                </div>}
              <div className="form-group row mb-3">
                <label className="col-sm-2 btn btn-outline-secondary fw-bold control-label">Environment:</label>
                <div className="col-sm-5">
                  <select className="form-control form-select" id="environment" onChange={this.onEnvChange} data-tip data-for="envs-tooltip">
                    <option defaultValue="prod" value="prod">Production</option>
                    <option value="np">Non-production</option>
                  </select>
                </div>
                <ReactTooltip className="col-sm-3" id="envs-tooltip" place="right" effect="solid">
                  environment where the auctions will be affected
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label className="col-sm-2 btn btn-outline-secondary fw-bold control-label">Type:</label>
                <div className="col-sm-5">
                  <select className="form-control form-select" id="type" onChange={this.onTypeChange} data-tip data-for="types-tooltip">
                    <option defaultValue="ws" value="ws">Web Service</option>
                    <option value="system">System</option>
                    <option value="all">All</option>
                  </select>
                </div>
                <ReactTooltip className="col-sm-3" id="types-tooltip" place="right" effect="solid">
                  "All" includes both "System" and "Web Service"
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Auctions:</label>
                <Select className="col-sm-5" data-tip='aaa' data-for="auctions-tooltip" placeholder="Select one or more auctions"
                  value={this.state.selectedAuctions} options={this.state.auctions} components={animatedComponents}
                  isMulti onChange={this.handleAuctionSelectionChange} />
                <ReactTooltip className="col-sm-3" id="auctions-tooltip" place="right" effect="solid">
                  Multiple Auctions can be selected
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Start Time (Eastern TZ) </label>
                <DateTimePicker
                  className="col-sm-5"
                  onChange={this.onStartChange}
                  value={this.state.start}
                  data-tip data-for="datetime-tooltip"
                />
                <ReactTooltip className="col-sm-3" id="datetime-tooltip" place="right" effect="solid">
                  date/time must be in the future
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Duration (mins):</label>
                <div className="col-sm-5">
                  <input
                    type="number"
                    onChange={this.onDurationChange}
                    value={this.state.duration}
                    data-tip data-for="duration-tooltip"
                  />
                </div>
                <ReactTooltip className="col-sm-3" id="duration-tooltip" place="right" effect="solid">
                  duration is in minutes
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Message:</label>
                <div className="col-sm-5">
                  <input
                    onChange={this.onMessageChange}
                    value={this.state.message}
                    size="65"  // have to use size to make the cell width same as above
                    data-tip data-for="message-tooltip"
                  />
                </div>
                <ReactTooltip className="col-sm-3" id="message-tooltip" place="right" effect="solid">
                  nessage is free text to indicate what the blackout is for
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Datadog Tag:</label>
                <div className="col-sm-5">
                  <input
                    onChange={this.onTagChange}
                    value={this.state.tag}
                    size="65"
                    data-tip data-for="tag-tooltip"
                  />
                </div>
                <ReactTooltip className="col-sm-3" id="tag-tooltip" place="right" effect="solid">
                  datadog tag used to select the datadog monitors
                </ReactTooltip>
              </div>
              <div className="form-group row mb-3">
                <label type="button" className="col-sm-2 btn btn-outline-secondary nohover fw-bold control-label">Additional Scope Keys:</label>
                <div className="col-sm-5">
                  <input
                    onChange={this.onScopeKeysChange}
                    value={this.state.scopekeys}
                    size="65"
                    data-tip data-for="scope-tooltip"
                  />
                </div>
                <ReactTooltip className="col-sm-3" id="scope-tooltip" place="right" effect="solid">
                  additional datadog scopes to "auction:" and "test_name:"
                </ReactTooltip>
              </div>
              <div className="form-group row">
                <div className="control">
                  <button type="submit" className="button is-primary is-medium">
                    Add blackout
                  </button>
                </div>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      </Fragment >
    )
  }
}
