import React, { Component } from 'react';
import axios from "axios";
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Blackouts from './components/Blackouts';
import Blackout from './components/Blackout';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import RegistrationWelcome from './components/auth/RegistrationWelcome';
import Auditlogs from './components/Auditlogs';
import About from './components/About';
import Footer from './components/Footer';
// import 'bootstrap/dist/bootstrap.css';
import { Auth } from 'aws-amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// import { V1URL } from './components/utility/URL'
import { V2URL } from './components/utility/URL'

const nonAuctions = ["Trigger", "Auth"];

library.add(fas);

class App extends Component {

  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
    prodAuctions: [],
    npAuctions: [],
    error: ''
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  fetchAuctions = async () => {
    // add call to AWS API Gateway to fetch auctions here
    // then set them in state
    try {
      console.log('fetching auctions list (prod, np) ....');

      let res = await axios.get(`${V2URL}/auctions`);

      console.log("res=", res);

      let prodAuctions = res.data.tests.filter((auction) => {
        return nonAuctions.indexOf(auction) === -1
      }).sort().map((a) => { return { label: a, value: a } });

      // prod auctions
      this.setState({ prodAuctions: prodAuctions });

      console.log("prod auctions=", prodAuctions);

      res = await axios.get(`${V2URL}/auctions?env=lower`);

      let npAuctions = res.data.tests.filter((auction) => {
        return nonAuctions.indexOf(auction) === -1
      }).sort().map((a) => { return { label: a, value: a } });

      // prod auctions
      this.setState({ npAuctions: npAuctions });

      // add * to only prod
      prodAuctions.unshift({ label: '*', value: '*' });
      //npAuctions.unshift({ label: '*', value: '*' });

      console.log("np auctions=", npAuctions);

      // set auctions to be prod auctions
      this.setState({ auctions: prodAuctions });

    } catch (error) {
      console.log(`An error has occurred: ${error.message}`);
      this.setState({ error: error.message });
    }
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);

      if (this.state.prodAuctions.length === 0) {
        // get auctions from api
        this.fetchAuctions();
      }

      console.log('App=', this);
    } catch (error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router>
          <div>
            <Navbar auth={authProps} />
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} auth={authProps} />} />
              <Route exact path="/home" render={(props) => <Home {...props} auth={authProps} />} />
              <Route exact path="/blackouts" render={(props) => this.state.isAuthenticated ? <Blackouts {...props} prodAuctions={this.state.prodAuctions} npAuctions={this.state.npAuctions} auth={authProps} /> : <Home {...props} auth={authProps} />} />
              <Route exact path="/add" render={(props) => this.state.isAuthenticated ? <Blackout {...props} prodAuctions={this.state.prodAuctions} npAuctions={this.state.npAuctions} parentError={this.state.error} auth={authProps} /> : <Home {...props} auth={authProps} />} />
              <Route exact path="/login" render={(props) => <LogIn {...props} auth={authProps} />} />
              <Route exact path="/register" render={(props) => <Register {...props} auth={authProps} />} />
              <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} auth={authProps} />} />
              <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props} auth={authProps} />} />
              <Route exact path="/changepassword" render={(props) => <ChangePassword {...props} auth={authProps} />} />
              <Route exact path="/changepasswordconfirmation" render={(props) => <ChangePasswordConfirm {...props} auth={authProps} />} />
              <Route exact path="/registrationwelcome" render={(props) => <RegistrationWelcome {...props} auth={authProps} />} />
              <Route exact path="/auditlogs" render={(props) => this.state.isAuthenticated ? <Auditlogs {...props} prodAuctions={this.state.prodAuctions} npAuctions={this.state.npAuctions} auth={authProps} /> : <Home {...props} auth={authProps} />} />
              <Route exact path="/about" render={(props) => this.state.isAuthenticated ? <About {...props} auth={authProps} /> : <Home {...props} auth={authProps} />} />
            </Switch>
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
